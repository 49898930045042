import React, { FunctionComponent } from "react";
import Header from "./header/Header";
import Footer from "./Footer";
import ContactUs from "../sections/ContactUs";
import { useCookieBanner } from "../../hooks/useCookieBanner";

type PageLayoutProps = {
  pageName?: string;
  darkHeader?: boolean;
  headerBg?: string;
  contactForm?: boolean;
};

const PageLayout: FunctionComponent<PageLayoutProps> = ({
  children,
  pageName,
  darkHeader,
  headerBg = darkHeader ? "gray-900" : "white",
  contactForm = true,
}) => {
  useCookieBanner();

  return (
    <div className="flex flex-col min-h-screen overflow-x-clip">
      <Header active={pageName} dark={darkHeader} bgColor={headerBg} />
      {children}
      {contactForm && <ContactUs />}
      <Footer />
    </div>
  );
};

export default PageLayout;
