import React, { FunctionComponent } from "react";

type SectionProps = {
  id?: string;
  className?: string;
  theme?: "white" | "gray" | "dark" | "indigo";
  noMargin?: "no-margin" | "top" | "bottom";
};

const Section: FunctionComponent<SectionProps> = ({
  children,
  className,
  id,
  theme = "white",
  noMargin,
}) => {
  const margin = noMargin
    ? ({
        "no-margin": "py-0",
        top: "pb-24",
        bottom: "pt-24",
      }[noMargin] ?? "py-24")
    : "py-24";

  return (
    <section
      id={id}
      className={`relative ${theme === "white" ? margin : "py-24"} ${
        theme === "gray"
          ? "bg-gray-100"
          : theme === "dark"
            ? "bg-gray-900"
            : theme === "indigo"
              ? "bg-indigo-900"
              : ""
      }`}
    >
      <div className={`container px-5 lg:px-12 mx-auto ${className || ""}`}>
        {children}
      </div>
    </section>
  );
};

export default Section;
